import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { TagField, useTags } from "@/components/custom/tag-input";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useNavigate, useParams } from "react-router-dom";
import { IPersona } from "./DemoPersonasPage";
import { useEffect, useState } from "react";
import { apiClient } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";
import { DefaultPersonaIcon } from "@/components/custom/icons";
import Loading from "@/components/custom/loading";

export const emptyPersona: IPersona = {
  personaId: '',
  name: '',
  commonJobTitles: [],
  description: '',
  icon: DefaultPersonaIcon,
  keyConcerns: [],
  keyObjectives: [],
  messagingExamples: [],
  painPoints: [],
  responsibilities: []
};

export default function DemoPersonaDetailpage() {
  const [personaDetails, setPersonaDetails] = useState<IPersona>(emptyPersona);
  const {
    tags: tagJobTitles,
    handleAddTag: handleAddTagJobTitle,
    handleRemoveTag: handleRemoveTagJobTitle,
  } = useTags(5, personaDetails.commonJobTitles);
  const { personaId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isInitialLoaded, setInitialLoaded] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState("Loading...");
  const navigate = useNavigate();

  useEffect(() => {
    if (personaId === "new") {
      setInitialLoaded(true);
      return;
    }
    setLoading(true);
    setLoadingTitle("Loading...");
    apiClient
      .get(
        `/agents/orgs/x-functions/knowledgebase-context/personas/${personaId}`
      )
      .then((res) => {
        setInitialLoaded(true);
        setPersonaDetails({
          ...res.data,
          icon: DefaultPersonaIcon,
        });
      })
      .catch((err) => {
        console.error("Error while fetching the persona: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [personaId]);

  const onPersonaDetailsChange = (fieldName: string, value: any) => {
    const newPersonaDetails = { ...personaDetails };
    newPersonaDetails[fieldName] = value;
    setPersonaDetails(newPersonaDetails);
  };

  const onSaveKnowledgebase = async () => {
    try {
      setLoading(true);
      setLoadingTitle("Saving...");
      let newPersonaDetails: IPersona = {
        ...personaDetails,
        commonJobTitles: tagJobTitles
      };
      if (!personaDetails.personaId) {
        const { data } = await apiClient.post(
          `/agents/orgs/x-functions/knowledgebase-context/personas`,
          newPersonaDetails
        );
        newPersonaDetails.personaId = data.personaId;
        setPersonaDetails({
          ...data,
          icon: DefaultPersonaIcon,
        });
      } else
        await apiClient.put(
          `/agents/orgs/x-functions/knowledgebase-context/personas/${newPersonaDetails.personaId}`,
          newPersonaDetails
        );
      const { data: newKnowledgebaseFile } = await apiClient.post(
        `/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`,
        {
          fileName: `New-Persona-Knowledgebase-File-${newPersonaDetails.personaId}.txt`,
          contentType: "text",
          knowledgeBaseId: Environment.KNOWLEDGE_BASE_ID,
        }
      );
      const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
      await axios.put(filePresignedUrl, JSON.stringify(newPersonaDetails), {
        headers: {
          "Content-Type": "text/plain",
        },
      });
      navigate(`/settings/personas/${newPersonaDetails.personaId}`);
    } catch (err) {
      console.error("Error while saving data: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <MainSidebar
        pageTitle={`Personas/${personaDetails.name || "New"}`}
        // shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onSaveKnowledgebase}>
            Save
          </Button>
        }
      >
        {isInitialLoaded && (
          <div className="text-cell px-8 py-5 max-w-[852px]">
            <div className="flex flex-col gap-2">
              <div className="font-medium">Persona name</div>
              <Textarea
                resizable={false}
                rows={1}
                withItems={null}
                type="text"
                value={personaDetails?.name}
                onChange={(e) => {
                  onPersonaDetailsChange("name", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Description</div>
              <Textarea
                resizable={false}
                withItems={null}
                type="text"
                value={personaDetails?.description}
                rows={4}
                onChange={(e) => {
                  onPersonaDetailsChange("description", e.target.value);
                }}
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add responsibility"
                label={"Primary responsibilities"}
                defaultValues={personaDetails?.responsibilities}
                onChange={(texts: string[]) =>
                  onPersonaDetailsChange("responsibilities", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add pain point"
                label={"Pain points"}
                defaultValues={personaDetails?.painPoints}
                onChange={(texts: string[]) =>
                  onPersonaDetailsChange("painPoints", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add concern"
                label={"Concerns"}
                defaultValues={personaDetails?.keyConcerns}
                onChange={(texts: string[]) =>
                  onPersonaDetailsChange("keyConcerns", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add objective"
                label={"Objectives"}
                defaultValues={personaDetails?.keyObjectives}
                onChange={(texts: string[]) =>
                  onPersonaDetailsChange("keyObjectives", texts)
                }
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Common job titles</div>
              <TagField
                tags={tagJobTitles}
                addTag={handleAddTagJobTitle}
                removeTag={handleRemoveTagJobTitle}
                maxTags={5}
                color="primary"
                placeholder="Type to add titles..."
              />
            </div>
            <div className="mt-20"></div>
          </div>
        )}
        {isLoading && (
          <div className="w-full h-full absolute top-0 left-0 bg-[#AAAAAA66]">
            <div className="fixed top-[40vh] left-[55%]">
              <Loading title={loadingTitle} />
            </div>
          </div>
        )}
      </MainSidebar>
    </main>
  );
}
