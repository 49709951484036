import { fetcher } from '@/services/api'
import { ICustomer } from '@/types/customer';
import useSWR from 'swr'

export function useCustomer(customerId: string) {
  const { data, error } = useSWR(`/agents/orgs/x-functions/customers/${customerId}`, fetcher);

  return {
    data: data as ICustomer | undefined,
    isLoading: !error && !data,
    isError: !!error
  }
};
