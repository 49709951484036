import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ISalesStage } from "./DemoSalesStagesPage";
import { ColorInput } from "@/components/custom/color-input";
import { apiClient } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";
import Loading from "@/components/custom/loading";

export const emptySalesStage: ISalesStage = {
  salesStageId: "",
  color: "#FFB800",
  exitCriteria: [],
  metrics: [],
  name: "",
  priorityActions: []
};

export default function DemoSalesStageDetailPage() {
  const { stageId } = useParams();
  const [salesStageDetails, setSalesStageDetails] =
    useState<ISalesStage>(emptySalesStage);
  const [isLoading, setLoading] = useState(false);
  const [isInitialLoaded, setInitialLoaded] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState("Loading...");
  const navigate = useNavigate();

  useEffect(() => {
    if (stageId === "new") {
      setInitialLoaded(true);
      return;
    }
    setLoading(true);
    setLoadingTitle("Loading...");
    apiClient
      .get(
        `/agents/orgs/x-functions/sales-stages/${stageId}`
      )
      .then((res) => {
        setInitialLoaded(true);
        setSalesStageDetails(res.data);
      })
      .catch((err) => {
        console.error("Error while fetching the sales stage: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [stageId]);

  const onStageDetailsChange = (fieldName: string, value: any) => {
    setSalesStageDetails(prevStageDetails => ({
      ...prevStageDetails,
      [fieldName]: value
    }));
  };

  const onSaveSalesStage = async () => {
    try {
      setLoading(true);
      setLoadingTitle("Saving...");
      let newSalesStageDetails: ISalesStage = {
        ...salesStageDetails,
      };
      if (!salesStageDetails.salesStageId) {
        const { data } = await apiClient.post(
          `/agents/orgs/x-functions/sales-stages`,
          newSalesStageDetails
        );
        newSalesStageDetails.salesStageId = data.salesStageId;
        setSalesStageDetails(data);
      } else
        await apiClient.put(
          `/agents/orgs/x-functions/sales-stages/${newSalesStageDetails.salesStageId}`,
          newSalesStageDetails
        );
      const { data: newKnowledgebaseFile } = await apiClient.post(
        `/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`,
        {
          fileName: `New-SalesStage-Knowledgebase-File-${newSalesStageDetails.salesStageId}.txt`,
          contentType: "text",
          knowledgeBaseId: Environment.KNOWLEDGE_BASE_ID,
        }
      );
      const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
      await axios.put(filePresignedUrl, JSON.stringify(newSalesStageDetails), {
        headers: {
          "Content-Type": "text/plain",
        },
      });
      navigate(`/settings/sales-stages/${newSalesStageDetails.salesStageId}`);
    } catch (err) {
      console.error("Error while saving data: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <MainSidebar
        pageTitle={`Sales stages/${salesStageDetails.name || "New"}`}
        // shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onSaveSalesStage}>
            Save
          </Button>
        }
      >
        {isInitialLoaded && (
          <div className="text-cell px-8 py-5 max-w-[852px]">
            <div className="flex flex-col gap-2">
              <div className="font-medium">Stage name</div>
              <ColorInput
                onChange={(value, color) => {
                  onStageDetailsChange("name", value);
                  onStageDetailsChange("color", color);
                }}
                value={salesStageDetails?.name}
                color={salesStageDetails?.color}
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add action"
                label={"Seller actions"}
                defaultValues={salesStageDetails?.priorityActions}
                onChange={(texts: string[]) =>
                  onStageDetailsChange("priorityActions", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add metric"
                label={"Metrics"}
                defaultValues={salesStageDetails?.metrics}
                onChange={(texts: string[]) =>
                  onStageDetailsChange("metrics", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add criteria"
                label={"Exit criteria"}
                defaultValues={salesStageDetails?.exitCriteria}
                onChange={(texts: string[]) =>
                  onStageDetailsChange("exitCriteria", texts)
                }
              />
            </div>
            <div className="mt-20"></div>
          </div>
        )}
        {isLoading && (
          <div className="w-full h-full absolute top-0 left-0 bg-[#AAAAAA66]">
            <div className="fixed top-[40vh] left-[55%]">
              <Loading title={loadingTitle} />
            </div>
          </div>
        )}
      </MainSidebar>
    </main>
  );
}
