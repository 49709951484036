import { fetcher } from '@/services/api'
import { getPageParamString, IPageParams } from '@/services/contact';
import { ICustomer } from '@/types/customer';
import useSWR from 'swr'

export function useCustomerList(pageParams?: IPageParams) {
  const { data, error } = useSWR(`/agents/orgs/x-functions/customers?${getPageParamString(pageParams)}`, fetcher);

  return {
    data: data as {
      items: ICustomer[];
      totalRows: number;
      offset: number;
    } | undefined,
    isLoading: !error && !data,
    isError: !!error
  }
};
