import pipe from 'lodash/fp/pipe';
import { TextFieldProps } from './text-field';

export const capitalize = ([first, ...rest]: string): string => {
  return `${first?.toUpperCase() ?? ''}${rest.join('')}`;
};

export const capitalizeAll = (text: string) =>
  text.split(' ').map(capitalize).join(' ');

export const camelToSentenceCase = (text: string) =>
  text
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z])([a-z0-9])/g, '$1 $2$3')
    .trim();

export const snakeToSentenceCase = (text: string) =>
  text.replace(/_/g, ' ').trim();

export const kebabToSentenceCase = (text: string) =>
  text.replace(/-/g, ' ').trim();

export const parseFieldName = pipe(
  camelToSentenceCase,
  snakeToSentenceCase,
  kebabToSentenceCase,
  capitalizeAll
);

export const parsePath = (p: string) =>
  `/${
    p
      ?.split('/')
      .filter((s) => !!s)
      .join('/') ?? ''
  }`;

export const comparePaths = (p1: string, p2: string) =>
  parsePath(p1) === parsePath(p2);

const LINE_HEIGHT_IN_EM = 23 / 16;

enum FontSize {
  medium = 14,
  small = 14,
}

enum PaddingHorizontal {
  medium = 16,
  small = 12,
}

enum Height {
  medium = 40,
  small = 32,
}

enum PaddingVertical {
  medium = (Height.medium - LINE_HEIGHT_IN_EM * FontSize.medium) / 2,
  small = (Height.small - LINE_HEIGHT_IN_EM * FontSize.small) / 2,
}

export const getInputStyle = (size: TextFieldProps['size'] = 'medium') => ({
  fontSize: FontSize[size],
  paddingTop: `${PaddingVertical[size]}px`,
  paddingBottom: `${PaddingVertical[size]}px`,
  paddingLeft: `${PaddingHorizontal[size]}px`,
  paddingRight: `${PaddingHorizontal[size]}px`,
  lineHeight: LINE_HEIGHT_IN_EM,
});

export const getLabelStyle = (size: TextFieldProps['size'] = 'medium') => {
  const focusedScale = 12 / FontSize[size];
  return {
    fontSize: FontSize[size],
    transform: `translate(${PaddingHorizontal[size]}px, ${PaddingVertical[size]}px) scale(1)`,
    '&.MuiInputLabel-shrink': {
      transform: `translate(${(8 + 6) / focusedScale}px, ${
        -8 / focusedScale
      }px) scale(${focusedScale})`,
    },
  };
};

export const formatFileSize = (bytes: number) => {
  if (!bytes) return '0 Bytes';
  
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);
  
  return `${formattedSize} ${sizes[i]}`;
}