import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "@/services/api";
import { ButtonPlusIcon, PlusIcon, SearchIcon } from "@/components/custom/icons";
import { Input, InputGroup } from "@/components/cataylst/input";
import { MenuIcon } from "@/components/custom/menu-icon";
import Loading from "@/components/custom/loading";

export interface ISalesStage {
  salesStageId: string;
  name: string;
  color: string;
  exitCriteria: string[];
  priorityActions: string[];
  metrics: string[];
};

interface ISalesStageItemProps {
  stage: ISalesStage;
  onClick?: Function;
}

const StageItem = ({ stage, onClick }: ISalesStageItemProps) => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  const onToggleStage = () => {
    setOpen(isOpen => !isOpen);
  };

  const stageDetails = stage as ISalesStage;

  return (
    <div className="w-full px-4 py-3 rounded-lg min-h-[42px] bg-[#F7F7F7] text-sm">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <div
            className="size-3 rounded-full"
            style={{ backgroundColor: stage.color }}
          ></div>
          <div className="font-semibold">{stage.name}</div>
        </div>
        <div className="flex gap-3 items-center">
          <div onClick={() => ((onClick || (() => {})))()}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 2.36426C10.6539 2.36426 10.3112 2.43243 9.99141 2.56488C9.67165 2.69733 9.3811 2.89147 9.13636 3.13621L8.47116 3.80141C8.47067 3.8019 8.47018 3.80239 8.46969 3.80288C8.4692 3.80336 8.46871 3.80385 8.46823 3.80434L2.13636 10.1362C1.9957 10.2769 1.91669 10.4676 1.91669 10.6665V13.3332C1.91669 13.7474 2.25247 14.0832 2.66669 14.0832H5.33335C5.53227 14.0832 5.72303 14.0042 5.86368 13.8635L12.197 7.5302L12.8637 6.86354C13.1084 6.6188 13.3026 6.32825 13.435 6.00848C13.5675 5.68871 13.6356 5.34599 13.6356 4.99988C13.6356 4.65376 13.5675 4.31104 13.435 3.99127C13.3026 3.6715 13.1084 3.38095 12.8637 3.13621C12.6189 2.89147 12.3284 2.69733 12.0086 2.56488C11.6889 2.43243 11.3461 2.36426 11 2.36426ZM11.6667 5.93921L11.803 5.80288C11.9085 5.69743 11.9921 5.57224 12.0492 5.43446C12.1063 5.29668 12.1356 5.14901 12.1356 4.99988C12.1356 4.85074 12.1063 4.70307 12.0492 4.56529C11.9921 4.42752 11.9085 4.30233 11.803 4.19687C11.6976 4.09142 11.5724 4.00777 11.4346 3.9507C11.2968 3.89363 11.1492 3.86426 11 3.86426C10.8509 3.86426 10.7032 3.89363 10.5654 3.9507C10.4277 4.00777 10.3025 4.09142 10.197 4.19687L10.0607 4.33321L11.6667 5.93921ZM9.00002 5.39387L10.606 6.99988L5.02269 12.5832H3.41669V10.9772L9.00002 5.39387Z"
                fill="black"
                fill-opacity="0.4"
              />
            </svg>
          </div>
          <div onClick={onToggleStage}>
            {!isOpen ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.46967 5.96967C3.76256 5.67678 4.23744 5.67678 4.53033 5.96967L8 9.43934L11.4697 5.96967C11.7626 5.67678 12.2374 5.67678 12.5303 5.96967C12.8232 6.26256 12.8232 6.73744 12.5303 7.03033L8.53033 11.0303C8.23744 11.3232 7.76256 11.3232 7.46967 11.0303L3.46967 7.03033C3.17678 6.73744 3.17678 6.26256 3.46967 5.96967Z"
                  fill="black"
                  fill-opacity="0.4"
                />
              </svg>
            ): (
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.46967 4.96967C7.76256 4.67678 8.23744 4.67678 8.53033 4.96967L12.5303 8.96967C12.8232 9.26256 12.8232 9.73744 12.5303 10.0303C12.2374 10.3232 11.7626 10.3232 11.4697 10.0303L8 6.56066L4.53033 10.0303C4.23744 10.3232 3.76256 10.3232 3.46967 10.0303C3.17678 9.73744 3.17678 9.26256 3.46967 8.96967L7.46967 4.96967Z" fill="black" fill-opacity="0.4"/>
  </svg>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-col gap-4 mt-4">
          {stageDetails.priorityActions && (
            <div>
              <div className="text-black opacity-60 text-xs leading-[21px]">
                Seller actions
              </div>
              <div className="flex flex-col gap-1">
                {stageDetails.priorityActions.map((item, index) => (
                  <div key={index} className="text-sm leading-[21px]">{item}</div>
                ))}
              </div>
            </div>
          )}
          {stageDetails.metrics && (
            <div>
              <div className="text-black opacity-60 text-xs leading-[21px]">
                Metrics
              </div>
              <div className="flex flex-col gap-1">
                {stageDetails.metrics.map((item, index) => (
                  <div key={index} className="text-sm leading-[21px]">{item}</div>
                ))}
              </div>
            </div>
          )}
          {stageDetails.exitCriteria && (
            <div>
              <div className="text-black opacity-60 text-xs leading-[21px]">
                Exit criteria
              </div>
              <div className="flex flex-col gap-1">
                {stageDetails.exitCriteria.map((item, index) => (
                  <div key={index} className="text-sm leading-[21px]">{item}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface IAddItemButtonProps {
  title: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};

const AddItemButton = ({ title, onClick }: IAddItemButtonProps) => {
  return (
    <div
      className="w-full border border-[#00000022] text-[#00000099] border-dashed rounded-lg px-4 h-[42px] flex gap-1.5 items-center text-sm cursor-pointer"
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.75003 3.33331C8.75003 2.9191 8.41424 2.58331 8.00003 2.58331C7.58582 2.58331 7.25003 2.9191 7.25003 3.33331V7.25H3.33337C2.91916 7.25 2.58337 7.58579 2.58337 8C2.58337 8.41421 2.91916 8.75 3.33337 8.75H7.25003V12.6666C7.25003 13.0809 7.58582 13.4166 8.00003 13.4166C8.41424 13.4166 8.75003 13.0809 8.75003 12.6666V8.75H12.6667C13.0809 8.75 13.4167 8.41421 13.4167 8C13.4167 7.58579 13.0809 7.25 12.6667 7.25H8.75003V3.33331Z"
          fill="black"
          fill-opacity="0.6"
        />
      </svg>
      <span>{title}</span>
    </div>
  )
};

export default function DemoSalesStagesPage() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [salesStages, setSalesStage] = useState<ISalesStage[]>([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
      setLoading(true);
      apiClient.get(`/agents/orgs/x-functions/sales-stages`)
        .then(res => {
          if (res.data.items.length > 0) {
            setSalesStage(res.data.items);
          }
        })
        .catch((err) => {
          console.error("Error while fetching sales stages: ", err);
        })
        .finally(() => {
          setLoading(false);
        })
    }, []);

  const onAddSalesStage = () => {
    navigate(`/settings/sales-stages/new`);
  };

  const onEditSalesStage = (stage: ISalesStage) => {
    navigate(`/settings/sales-stages/${stage.salesStageId}`);
  };

  const onSearchSalesStages = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const filterSalesStages = () => {
    return salesStages.filter(stage => 
      stage.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );
  }

  return (
    <main>
      <MainSidebar
        pageTitle="Sales stages"
        shouldHideActionButton
        toolbar={
          <Button color="dark" className="gap-2 flex items-center">
            <img src={PlusIcon} className="w-4 h-4" />
            Add stage
          </Button>
        }
      >
        <div className="text-cell px-8 py-5">
          <div className="flex flex-col gap-2 align-center">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon
                  src={SearchIcon}
                  data-slot="icon"
                  className="!size-4"
                />
                <Input
                  placeholder="Search sales stages"
                  className="h-9 pl-9 pr-2 !rounded-full border-none !bg-[#F2F2F2]"
                  onChange={onSearchSalesStages}
                  value={searchKeyword}
                  color=''
                />
              </InputGroup>
            </div>
          </div>
          {!isLoading && (
            <div className="mt-5">
              <div className="font-medium mb-4">Sales stages</div>
              <div>
                {filterSalesStages().map((stage) => (
                  <StageItem stage={stage} key={stage.salesStageId} onClick={() => onEditSalesStage(stage)} />
                ))}
              </div>
              <div
                className="w-full border mt-5 border-[#00000022] text-[#00000099] border-dashed rounded-lg px-4 h-[42px] flex gap-1.5 items-center text-sm cursor-pointer"
                onClick={onAddSalesStage}
              >
                <img src={ButtonPlusIcon} className="w-4 h-4" />
                <span>Add sales stage</span>
              </div>
            </div>
          )}
          {isLoading && (
            <div className="w-full h-full mt-4">
              <Loading />
            </div>
          )}
        </div>
      </MainSidebar>
    </main>
  );
}
