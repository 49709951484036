import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useNavigate, useParams } from "react-router-dom";
import { IUseCase } from "./DemoUseCasesPage";
import { useEffect, useState } from "react";
import { apiClient } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";
import { DefaultUsecaseIcon } from "@/components/custom/icons";
import Loading from "@/components/custom/loading";

export const emptyUsecase: IUseCase = {
  useCaseId: "",
  challenges: [],
  description: "",
  desiredOutcomes: [],
  icon: DefaultUsecaseIcon,
  name: "",
  relatedProof: "",
};

export default function DemoUseCaseDetailpage() {
  const { usecaseId } = useParams();
  const [usecaseDetails, setUsecaseDetails] = useState<IUseCase>(emptyUsecase);
  const [isLoading, setLoading] = useState(false);
  const [isInitialLoaded, setInitialLoaded] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState("Loading...");
  const navigate = useNavigate();

  useEffect(() => {
    if (usecaseId === "new") {
      setInitialLoaded(true);
      return;
    }
    setLoading(true);
    setLoadingTitle("Loading...");
    apiClient
      .get(
        `/agents/orgs/x-functions/knowledgebase-context/usecases/${usecaseId}`
      )
      .then((res) => {
        setInitialLoaded(true);
        setUsecaseDetails({
          ...res.data,
          icon: DefaultUsecaseIcon,
        });
      })
      .catch((err) => {
        console.error("Error while fetching the usecase: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [usecaseId]);

  const onUsecaseDetailsChange = (fieldName: string, value: any) => {
    const newUsecaseDetails = { ...usecaseDetails };
    newUsecaseDetails[fieldName] = value;
    setUsecaseDetails(newUsecaseDetails);
  };

  const onSaveKnowledgebase = async () => {
    try {
      setLoading(true);
      setLoadingTitle("Saving...");
      let newUsecaseDetails: IUseCase = {
        ...usecaseDetails,
      };
      if (!usecaseDetails.useCaseId) {
        const { data } = await apiClient.post(
          `/agents/orgs/x-functions/knowledgebase-context/usecases`,
          newUsecaseDetails
        );
        newUsecaseDetails.useCaseId = data.useCaseId;
        setUsecaseDetails({
          ...data,
          icon: DefaultUsecaseIcon,
        });
      } else
        await apiClient.put(
          `/agents/orgs/x-functions/knowledgebase-context/usecases/${newUsecaseDetails.useCaseId}`,
          newUsecaseDetails
        );
      const { data: newKnowledgebaseFile } = await apiClient.post(
        `/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`,
        {
          fileName: `New-Usecase-Knowledgebase-File-${newUsecaseDetails.useCaseId}.txt`,
          contentType: "text",
          knowledgeBaseId: Environment.KNOWLEDGE_BASE_ID,
        }
      );
      const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
      await axios.put(filePresignedUrl, JSON.stringify(newUsecaseDetails), {
        headers: {
          "Content-Type": "text/plain",
        },
      });
      navigate(`/settings/use-cases/${newUsecaseDetails.useCaseId}`);
    } catch (err) {
      console.error("Error while saving data: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <MainSidebar
        pageTitle={`UseCases/${usecaseDetails.name || "New"}`}
        // shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onSaveKnowledgebase}>
            Save
          </Button>
        }
      >
        {isInitialLoaded && (
          <div className="text-cell px-8 py-5 max-w-[852px]">
            <div className="flex flex-col gap-2">
              <div className="font-medium">Use case name</div>
              <Textarea
                resizable={false}
                rows={1}
                withItems={null}
                type="text"
                value={usecaseDetails?.name}
                onChange={(e) => {
                  onUsecaseDetailsChange("name", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Description</div>
              <Textarea
                resizable={false}
                withItems={null}
                type="text"
                value={usecaseDetails?.description}
                rows={4}
                onChange={(e) => {
                  onUsecaseDetailsChange("description", e.target.value);
                }}
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add challenge"
                label={"Challenges"}
                defaultValues={usecaseDetails?.challenges}
                onChange={(texts: string[]) =>
                  onUsecaseDetailsChange("challenges", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add outcome"
                label={"Desired outcomes"}
                defaultValues={usecaseDetails?.desiredOutcomes}
                onChange={(texts: string[]) =>
                  onUsecaseDetailsChange("desiredOutcomes", texts)
                }
              />
            </div>
            <div className="mt-20"></div>
          </div>
        )}
        {isLoading && (
          <div className="w-full h-full absolute top-0 left-0 bg-[#AAAAAA66]">
            <div className="fixed top-[40vh] left-[55%]">
              <Loading title={loadingTitle} />
            </div>
          </div>
        )}
      </MainSidebar>
    </main>
  );
}
