import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { TagField, useTags } from "@/components/custom/tag-input";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useNavigate, useParams } from "react-router-dom";
import { IProduct } from "./DemoProductsPage";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { apiClient } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";
import Loading from "@/components/custom/loading";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "@/components/cataylst/dropdown";
import { formatFileSize } from "@/components/custom/form/utils";
import { IFileUploadResult } from "@/types/file";
import { DefaultProductIcon, EllipsisIcon, FileImageIcon } from "@/components/custom/icons";

export const emptyProduct: IProduct = {
  productId: "",
  name: "",
  description: "",
  currentChallenges: [],
  valuePropositions: [],
  features: [],
  customerBenefits: [],
  idealCustomerProfile: [],
  documentationPath: "",
  integrationCapabilities: [],
  documentationFileSize: 0,
  icon: DefaultProductIcon,
};

export default function DemoProductDetailPage() {
  const [productDetails, setProductDetails] = useState<IProduct>(emptyProduct);
  const [fileUploadResult, setFileUploadResult] = useState<IFileUploadResult>();
  const {
    tags: tagCapabilities,
    handleAddTag: handleAddTagCapability,
    handleRemoveTag: handleRemoveTagCapability,
  } = useTags(5, productDetails.integrationCapabilities);
  const {
    tags: tagCustomerProfiles,
    handleAddTag: handleAddTagCustomerProfile,
    handleRemoveTag: handleRemoveTagCustomerProfile,
  } = useTags(5, productDetails.idealCustomerProfile);

  const { productId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isInitialLoaded, setInitialLoaded] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState("Loading...");
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (productId === "new") {
      setInitialLoaded(true);
      return;
    }
    setLoading(true);
    setLoadingTitle("Loading...");
    apiClient
      .get(
        `/agents/orgs/x-functions/knowledgebase-context/products/${productId}`
      )
      .then((res) => {
        setInitialLoaded(true);
        setProductDetails({
          ...res.data,
          icon: DefaultProductIcon,
        });
      })
      .catch((err) => {
        console.error("Error while fetching the product: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [productId]);

  const onProductDetailsChange = (fieldName: string, value: any) => {
    const newProductDetails = { ...productDetails };
    newProductDetails[fieldName] = value;
    setProductDetails(newProductDetails);
  };

  const onSaveKnowledgebase = async () => {
    try {
      setLoading(true);
      setLoadingTitle("Saving...");
      let newProductDetails: IProduct = {
        ...productDetails,
        idealCustomerProfile: tagCustomerProfiles,
        integrationCapabilities: tagCapabilities,
      };
      if (!productDetails.productId) {
        const { data } = await apiClient.post(
          `/agents/orgs/x-functions/knowledgebase-context/products`,
          newProductDetails
        );
        newProductDetails.productId = data.productId;
        setProductDetails({
          ...data,
          icon: DefaultProductIcon,
        });
      } else
        await apiClient.put(
          `/agents/orgs/x-functions/knowledgebase-context/products/${newProductDetails.productId}`,
          newProductDetails
        );
      const { data: newKnowledgebaseFile } = await apiClient.post(
        `/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`,
        {
          fileName: `New-Product-Knowledgebase-File-${newProductDetails.productId}.txt`,
          contentType: "text",
          knowledgeBaseId: Environment.KNOWLEDGE_BASE_ID,
        }
      );
      const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
      await axios.put(filePresignedUrl, JSON.stringify(newProductDetails), {
        headers: {
          "Content-Type": "text/plain",
        },
      });
      navigate(`/settings/products/${newProductDetails.productId}`);
    } catch (err) {
      console.error("Error while saving data: ", err);
    } finally {
      setLoading(false);
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const onFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      setLoadingTitle("Uploading...");
      // Get the pre-signed URL
      const { data: fileUploadResult } = await apiClient.post(
        `/agents/orgs/x-functions/general/files/upload-url`,
        {
          fileName: file.name,
          category: "knowledgebase-product-documents",
        }
      );

      setFileUploadResult(fileUploadResult);

      setProductDetails({
        ...productDetails,
        documentationPath: fileUploadResult.s3ObjectKey,
        documentationFileSize: file.size,
      });

      // Upload the file using the pre-signed URL
      await axios.put(fileUploadResult.uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onViewFile = async () => {
    window.open(
      `${Environment.S3_STORAGE_URL}/${productDetails.documentationPath}`,
      "_blank"
    );
  };

  const onDeleteFile = async () => {
    setFileUploadResult(undefined);
    setProductDetails({
      ...productDetails,
      documentationPath: '',
      documentationFileSize: 0
    });
  };

  return (
    <main>
      <MainSidebar
        pageTitle={`Products/${productDetails.name || "___"}`}
        // shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onSaveKnowledgebase}>
            Save
          </Button>
        }
      >
        {isInitialLoaded && (
          <div className="text-cell px-8 py-5 max-w-[852px]">
            <div className="flex flex-col gap-2">
              <div className="font-medium">Product name</div>
              <Textarea
                resizable={false}
                rows={1}
                withItems={null}
                type="text"
                value={productDetails?.name}
                onChange={(e) => {
                  onProductDetailsChange("name", e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Description</div>
              <Textarea
                resizable={false}
                withItems={null}
                type="text"
                value={productDetails?.description}
                rows={4}
                onChange={(e) => {
                  onProductDetailsChange("description", e.target.value);
                }}
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add challenge"
                label={"Challenges - status quo"}
                defaultValues={productDetails?.currentChallenges}
                onChange={(texts: string[]) =>
                  onProductDetailsChange("currentChallenges", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add value prop"
                label={"Differentiated value"}
                defaultValues={productDetails?.valuePropositions}
                onChange={(texts: string[]) =>
                  onProductDetailsChange("valuePropositions", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add capability"
                label={"Capabilities"}
                defaultValues={productDetails?.features}
                onChange={(texts: string[]) =>
                  onProductDetailsChange("features", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add benefit"
                label={"Customer benefits"}
                defaultValues={productDetails?.customerBenefits}
                onChange={(texts: string[]) =>
                  onProductDetailsChange("customerBenefits", texts)
                }
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Integration capabilities</div>
              <TagField
                tags={tagCapabilities}
                addTag={handleAddTagCapability}
                removeTag={handleRemoveTagCapability}
                maxTags={5}
                color="success"
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Product ICP</div>
              <TagField
                tags={tagCustomerProfiles}
                addTag={handleAddTagCustomerProfile}
                removeTag={handleRemoveTagCustomerProfile}
                maxTags={5}
                color="primary"
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Product documentation</div>
              {productDetails.documentationPath && (
                <div className="w-full py-4 pl-4 pr-6 rounded-lg h-[64px] bg-[#F7F7F7] text-sm">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-3">
                      <img src={FileImageIcon} className="w-8 h-8" />
                      <div className="flex flex-col gap-0.5">
                        <div>
                          {productDetails.documentationPath.split("/").pop()}
                        </div>
                        <div className="opacity-60 text-xs">
                          DOC -{" "}
                          {formatFileSize(productDetails.documentationFileSize)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <Dropdown>
                        <DropdownButton outline className="!border-none !p-0">
                          <img src={EllipsisIcon} className="w-4 h-4" />
                        </DropdownButton>
                        <DropdownMenu className="min-w-48">
                          <DropdownItem onClick={onViewFile}>
                            View File
                          </DropdownItem>
                          <DropdownItem onClick={onDeleteFile}>
                            Remove File
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="w-full border border-[#106EF6] border-dashed rounded-lg h-[64px] bg-[#F8FAFE] flex items-center justify-center text-sm cursor-pointer"
                onClick={triggerFileInput}
              >
                Drag here to upload document, or&nbsp;
                <span className="underline">browse files</span>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={onFileUpload}
                accept=".pdf,.doc,.docx,.txt"
              />
            </div>
            <div className="mt-20"></div>
          </div>
        )}
        {isLoading && (
          <div className="w-full h-full absolute top-0 left-0 bg-[#AAAAAA66]">
            <div className="fixed top-[40vh] left-[55%]">
              <Loading title={loadingTitle} />
            </div>
          </div>
        )}
      </MainSidebar>
    </main>
  );
}
