import { IBeefreeTemplate } from '@/integrations/beefree/beefree.type';
import { create } from 'zustand';

interface ContextStore {
  currentUser: {
    id: string;
    name: string;
    email: string;
    socialLogin: boolean;
    picture?: string;
    iat?: number;
  };
  roles: Record<string, string>;
  selectedOrgId?: string;
  permissions: {
    canManageOrgMembers: boolean;
  };
  currentBeefreeTemplate?: IBeefreeTemplate;
  agentChatEvent: {
    eventId: number;
    userPrompt: string;
  },
  setCurrentUser: (currentUser: ContextStore['currentUser']) => void;
  setRoles: (roles: ContextStore['roles']) => void;
  setSelectedOrgId: (selectedOrgId: ContextStore['selectedOrgId']) => void;
  setCurrentBeefreeTemplate: (template: ContextStore['currentBeefreeTemplate']) => void;
  setAgentChatEvent: (agentChatEvent: { userPrompt: string; eventId: number; }) => void;
}

export const useContextStore = create<ContextStore>((set) => ({
  currentUser: {
    id: '',
    name: '',
    email: '',
    socialLogin: false,
  },
  roles: {},
  selectedOrgId: undefined,
  permissions: {
    canManageOrgMembers: false,
  },
  currentBeefreeTemplate: undefined,
  agentChatEvent: {
    eventId: 0,
    userPrompt: ''
  },
  setCurrentUser: (currentUser) => {
    set({ currentUser });
  },
  setRoles: (roles) => {
    set({ roles });
  },
  setSelectedOrgId: (selectedOrgId) => {
    set({ selectedOrgId });
  },
  setCurrentBeefreeTemplate: (currentBeefreeTemplate) => {
    set({ currentBeefreeTemplate });
  },
  setAgentChatEvent: (agentChatEvent) => {
    set({ agentChatEvent });
  }
}));