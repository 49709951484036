import { Badge } from "@/components/cataylst/badge";
import { Checkbox } from "@/components/cataylst/checkbox";
import { Input, InputGroup } from "@/components/cataylst/input";
import { Link } from "@/components/cataylst/link";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/cataylst/table";
import {
  AIIcon,
  SearchIcon,
} from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { useState } from "react";
import { ProgressBar } from "@/components/custom/progressbar";
// import {
//   Pagination,
//   PaginationList,
//   PaginationNext,
//   PaginationPage,
//   PaginationPrevious,
// } from "@/components/cataylst/pagination";
import Loading from "@/components/custom/loading";
import { useSearchParams } from "react-router-dom";
import { useCustomerList } from "@/hooks/useCustomerList";

const PAGE_SIZE = 14;

const getTagColor = (tag: string) => {
  const colors = {
    Event: "customPurpleLight",
    Partner: "yellow",
    Inbound: "customGreenLight",
    Outbound: "customBlueLight",
    "Content Marketing": "customRedLight",
    Referral: "amber",
  };
  return colors[tag] || "customBlueLight";
};

// const getSalesStageColor = (salesStage: string) => {
//   const colors = {
//     Prospect: "customPurpleMain",
//     Won: "customYellowMain",
//     Propose: "customFuchsiaMain",
//     Negotiate: "customBlueMain",
//     Implement: "customRedMain",
//     Success: "customGreenMain",
//   };
//   return colors[salesStage] || "customBlueMain";
// };

// const getCustomerJourneyColor = (customerJourney: string) => {
//   const colors = {
//     Awareness: "customPurpleLight",
//     Purchase: "yellow",
//     Evaluation: "customGreenLight",
//     Decision: "customBlueLight",
//     Implementation: "customRedLight",
//     Retention: "amber",
//   };
//   return colors[customerJourney] || "customBlueLight";
// };

const mockTags = {
  'Visa': ['Referral', 'Partner'],
  'Citi': ['Outbound', 'Content Marketing'],
  'Target': ['Inbound', 'Event'],
  'Chevron': ['Partner', 'Event'],
  'Eli Lilly and Company': ['Outbound, Referral'],
  'Zoom': ['Inbound', 'Content Marketing']
};

const mockDealProgress = {
  'Visa': 55,
  'Citi': 38,
  'Target': 56,
  'Chevron': 31,
  'Eli Lilly and Company': 29,
  'Zoom': 80
};

export default function DemoCompaniesPage() {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get("keyword") || "";
  const pageNum = Number(searchParams.get("page")) || 1;
  const [searchKeyword, setSearchKeyword] = useState(keyword || "");
  const { data: customerListData, isLoading } = useCustomerList({ cursor: (pageNum-1)*PAGE_SIZE, limit: PAGE_SIZE });
  const customers = customerListData?.items || [];

  const onSearchCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  // const totalRows = isLoading ? -1 : customerListData?.totalRows;

  const filterCustomers = () => {
    return customers.filter(customer => customer.name.toLowerCase().includes(searchKeyword.toLowerCase()))
      .map((customer) => ({
        ...customer,
        tags: mockTags[customer.name],
        dealProgress: mockDealProgress[customer.name],
        nextAction: {

        }
      }));
  };

  // const renderPagination = () => {
  //   const totalPages = Math.ceil(totalRows / PAGE_SIZE);
  //   const prevPage = pageNum > 1 ? pageNum - 1 : 1;
  //   const nextPage = pageNum < totalPages ? pageNum + 1 : totalPages;
  
  //   const getPageNumbers = () => {
  //     const pageNumbers: any[] = [];
  //     const showPagesCount = 5; // Number of pages to show
      
  //     if (totalPages <= showPagesCount) {
  //       // If total pages are less than showPagesCount, show all pages
  //       for (let i = 1; i <= totalPages; i++) {
  //         pageNumbers.push(i);
  //       }
  //     } else {
  //       // Always show first page
  //       pageNumbers.push(1);
        
  //       let startPage = Math.max(2, pageNum - 1);
  //       let endPage = Math.min(totalPages - 1, pageNum + 1);
        
  //       // Adjust startPage and endPage to always show showPagesCount-2 pages
  //       if (startPage <= 2) {
  //         endPage = showPagesCount - 1;
  //       } else if (endPage >= totalPages - 1) {
  //         startPage = totalPages - (showPagesCount - 2);
  //       }
        
  //       // Add ellipsis after first page
  //       if (startPage > 2) {
  //         pageNumbers.push('...');
  //       }
        
  //       // Add middle pages
  //       for (let i = startPage; i <= endPage; i++) {
  //         pageNumbers.push(i);
  //       }
        
  //       // Add ellipsis before last page
  //       if (endPage < totalPages - 1) {
  //         pageNumbers.push('...');
  //       }
        
  //       // Always show last page
  //       pageNumbers.push(totalPages);
  //     }
      
  //     return pageNumbers;
  //   };
  
  //   return (
  //     <Pagination className="mt-6">
  //       <PaginationPrevious
  //         href={`?page=${prevPage}`}
  //       />
  //       <PaginationList>
  //         {getPageNumbers().map((pageNumber, index) => (
  //           pageNumber === '...' ? (
  //             <span key={`ellipsis-${index}`} className="px-2">...</span>
  //           ) : (
  //             <PaginationPage
  //               key={pageNumber}
  //               href={`?page=${pageNumber}`}
  //               current={pageNumber === pageNum}
  //             >
  //               {pageNumber}
  //             </PaginationPage>
  //           )
  //         ))}
  //       </PaginationList>
  //       <PaginationNext href={`?page=${nextPage}`} />
  //     </Pagination>
  //   );
  // };

  return (
    <main>
      <MainSidebar
        // toolbar={
        //   <Button color="dark" className="gap-2 flex items-center">
        //     <img src={PlusIcon} className="w-4 h-4" />
        //     Add company
        //   </Button>
        // }
      >
        <div className="text-cell px-8 py-5">
          <div className="mb-5 flex justify-between gap-4 pt-3">
            <div className="flex flex-col gap-2 align-center">
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon
                    src={SearchIcon}
                    data-slot="icon"
                    className="!size-4"
                  />
                  <Input
                    placeholder="Search companies"
                    className="h-9 pl-9 pr-2 !rounded-full border-none !bg-[#F2F2F2]"
                    onChange={onSearchCompanies}
                    value={searchKeyword}
                    color=''
                  />
                </InputGroup>
              </div>
            </div>
            {/* <div className="flex gap-4 items-center">
              <div className="flex gap-2 items-center tracking-widest min-w-fit">
                <img src={AIStarIcon} className="w-4 h-4" />
                <span className="font-medium min-w-fit">AI FILTERS</span>
              </div>
              <div className="flex flex-wrap gap-2 items-center">
                <Button color="white" size="sm">
                  Industry: <span className="text-gray-500">Technology</span>
                </Button>
                <Button color="white" size="sm">
                  Location: <span className="text-gray-500">US, AU, CA, IL</span>
                </Button>
                <Button color="white" size="sm">
                  SEC Keywords: <span className="text-gray-500">AI, Artificial Intelligence, Machine Learning,
                  Automation</span>
                </Button>
              </div>
            </div> */}
          </div>
          <div className="max-w-full">
            <Table
              grid
              dense={true}
              fixedFirstColumn={true}
              fixedHeader={true}
              fixedLastColumn={true}
            >
              <TableHead>
                <TableRow>
                  <TableHeader className="bg-white">
                    <div className="flex gap-4 items-center">
                      <Checkbox />
                      <span>Company</span>
                    </div>
                  </TableHeader>
                  <TableHeader>Location</TableHeader>
                  <TableHeader>Industry</TableHeader>
                  <TableHeader>Tags</TableHeader>
                  {/* <TableHeader>Sales Stage</TableHeader>
                  <TableHeader>Customer Journey</TableHeader> */}
                  <TableHeader>Deal Progress</TableHeader>
                  <TableHeader>Next Action</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterCustomers().map((company) => (
                  <TableRow key={company.customerId}>
                    <TableCell className="bg-white !border-r">
                      <div className="flex gap-4 items-center">
                        <Checkbox />
                        <Link
                          className="flex gap-2 items-center"
                          href={`/customers/companies/${company.customerId}/account-plan`}
                        >
                          <img src={company.logoUrl} className="size-4 rounded-full" />
                          <div className="underline">{company.name}</div>
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell>{company.city}, {company.state}</TableCell>
                    <TableCell>{company.industry}</TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        {company.tags.map((tag) => (
                          <Badge color={getTagColor(tag)} key={tag}>
                            {tag}
                          </Badge>
                        ))}
                      </div>
                    </TableCell>
                    {/* <TableCell>
                      <div className="flex gap-2 items-center">
                        <Badge
                          color={getSalesStageColor(company.salesStage.label)}
                        >
                          {company.salesStage.label || "N/A"}
                        </Badge>
                        <TooltipClaim id={`salesStage-tooltip-${company.id}`}>
                          {company.salesStage.description}
                        </TooltipClaim>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <Badge
                          color={getCustomerJourneyColor(
                            company.customerJourneyStage.label
                          )}
                        >
                          {company.customerJourneyStage.label}
                        </Badge>
                        <TooltipClaim id={`journey-tooltip-${company.id}`}>
                          {company.customerJourneyStage.description}
                        </TooltipClaim>
                      </div>
                    </TableCell> */}
                    <TableCell>
                      <ProgressBar value={company.dealProgress}>
                        {company.dealProgress}%
                      </ProgressBar>
                    </TableCell>
                    <TableCell>
                      <a className="flex gap-2 items-center" href="#">
                        <img
                          src={AIIcon}
                          className="w-4 h-4"
                        />
                        <div className="underline">
                          AI Automation
                        </div>
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* {renderPagination()} */}
            {isLoading && (
              <div className="w-full h-full mt-4">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </MainSidebar>
    </main>
  );
}
