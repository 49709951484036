import { Avatar } from "@/components/cataylst/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@/components/cataylst/dropdown";
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSectionLabel,
} from "@/components/cataylst/sidebar";
import { SidebarLayout } from "@/components/cataylst/sidebar-layout";
import {
  ArrowRightStartOnRectangleIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  UserIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/16/solid";
import clsx from "clsx";
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { CircularButton } from "./circular-button";
import { InboxIcon, LeftArrowIcon } from "../custom/icons";
import { CloseLeftIcon, CloseRightIcon, LogoIcon } from "./icons";
import { MenuIcon } from "./menu-icon";
import {
  bloomMenuItems,
  getCurrentSubMenuItems,
  getPageTitle,
  IMenuItem,
} from "@/utils/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BloomStorage } from "@/utils/storage";
import MainDrawer from "./drawer";
import { Navbar, NavbarSpacer } from "../cataylst/navbar";
import { useContextStore } from "@/zustand/context";
import AgentChat, { AgentChatLayout, IAgentChatType } from "./agent-chat";
import AgentInput from "./agent-input";
import SourceDrawer from "./source-drawer";
import { SourceId } from "./source-icon";
import NotificationDrawer from "./notification-drawer";
import { useBusiness } from "@/hooks/useBusiness";
import { useCustomer } from "@/hooks/useCustomer";

interface IMainSidebarProps {
  toolbar?: ReactNode;
  shouldHideTab?: boolean;
  pageTitle?: string;
  pageIcon?: string;
  shouldHideActionButton?: boolean;
  triggerDrawerOpen?: number;
  triggerSourceDrawerOpen?: number;
  sourceId?: SourceId;
}

const MainSidebar: FC<PropsWithChildren<IMainSidebarProps>> = ({
  children,
  toolbar,
  pageTitle,
  pageIcon,
  shouldHideActionButton,
  triggerDrawerOpen,
  triggerSourceDrawerOpen,
  shouldHideTab,
  sourceId,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isSourceDrawerOpen, setSourceDrawerOpen] = useState(false);
  const [isInboxDrawerOpen, setInboxDrawerOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(
    BloomStorage.isSidebarOpen()
  );
  const { pathname } = useLocation();
  const { currentUser, agentChatEvent } = useContextStore();
  const { companyId, contactId } = useParams();

  const [agentType, setAgentType] = useState(0);

  const { data: businessData, isLoading } = useCustomer(companyId || '');
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  useEffect(() => {
    if (agentChatEvent.eventId > 0)
      setDrawerOpen(true);
  }, [agentChatEvent.eventId]);

  useLayoutEffect(() => {
    checkOverflow();
  }, []);

  useEffect(() => {
    if (triggerDrawerOpen) {
      onDrawerOpen(true);
    }
  }, [triggerDrawerOpen]);

  useEffect(() => {
    if (triggerSourceDrawerOpen) {
      onSourceDrawerOpen(true);
    }
  }, [triggerSourceDrawerOpen]);

  const onDrawerOpen = (open: boolean) => {
    setDrawerOpen(open);
    setSourceDrawerOpen(false);
    setInboxDrawerOpen(false);
  };

  const onSourceDrawerOpen = (open: boolean) => {
    setDrawerOpen(false);
    setSourceDrawerOpen(open);
    setInboxDrawerOpen(false);
  };

  const onInboxDrawerOpen = (open: boolean) => {
    setDrawerOpen(false);
    setSourceDrawerOpen(false);
    setInboxDrawerOpen(open);
  };

  const agentTypes: IAgentChatType[] = [
    "BULK_ACTIONS",
    "ALERT",
    "SUGGESTION",
    "INSIGHTS",
    "NOTIFICATIONS",
  ];
  useEffect(() => {
    const agentTimer = setInterval(() => {
      setAgentType((agentType) => (agentType + 1) % agentTypes.length);
    }, 3000);
    return () => {
      clearInterval(agentTimer);
    };
  }, []);

  const onToggleSidebar = () => {
    setSidebarOpen((isSidebarOpen) => !isSidebarOpen);
    BloomStorage.setSidebarOpen(!isSidebarOpen);
  };

  const renderMenuItem = (menuItem: IMenuItem) => {
    if (!menuItem.slug) {
      return (
        <SidebarDivider className={`${isSidebarOpen ? "mx-[10px]" : "mx-0"}`} />
      );
    }
    return (
      <SidebarItem
        href={menuItem.slug}
        current={pathname.startsWith(menuItem.slug)}
      >
        <MenuIcon src={menuItem.icon!} opacity={0.6} />
        {isSidebarOpen && <SidebarLabel>{menuItem.label}</SidebarLabel>}
      </SidebarItem>
    );
  };

  const isActiveTab = (slug: string) => {
    const slugs = [
      "/tasks-notes",
      "/contacts",
      "/documents",
      "/activities",
      "/insights",
      "/account-plan",
    ];
    return (
      pathname === slug ||
      slugs.some((url) => pathname.endsWith(url) && slug?.endsWith(url))
    );
  };

  const tabs = getCurrentSubMenuItems(pathname);

  const checkOverflow = () => {
    const sidebarHeader = document.getElementById("sidebarHeader");
    const primaryContainer = document.getElementById("primaryTabs");
    const secondaryContainer = document.getElementById("secondaryTabs");
    if (primaryContainer && secondaryContainer && sidebarHeader) {
      const isOverflowing = sidebarHeader.scrollWidth < 750;

      if (isOverflowing) {
        primaryContainer.style.display = "none";
        secondaryContainer.style.display = "block";
      } else {
        primaryContainer.style.display = "flex";
        secondaryContainer.style.display = "none";
      }
    }
  };

  const getPageUrl = (tabSlug?: string) => {
    let url = tabSlug;
    if (companyId) {
      url = url?.replace("{companyId}", companyId);
    }
    if (contactId) {
      url = url?.replace("{contactId}", contactId);
    }
    return url;
  };

  const onToggleInbox = () => {
    setInboxDrawerOpen(!isInboxDrawerOpen);
  };

  const getPrimarySidebar = () => {
    return (
      <Sidebar>
        <SidebarHeader>
          <div className="flex gap-2 items-center mb-[21px]">
            <Avatar src={LogoIcon} square={true} outline={false} />
            <SidebarLabel className="text-title">Bloom</SidebarLabel>
          </div>
        </SidebarHeader>
        <SidebarBody>
          <SidebarSection>
            {/* <SidebarItem onClick={onToggleInbox}>
              <MenuIcon src={InboxIcon} opacity={0.6} />
              {isSidebarOpen && <SidebarLabel>Notifications</SidebarLabel>}
              <div className="rounded-full size-2 bg-red-600 absolute top-2 left-5" />
            </SidebarItem>
            <SidebarDivider
              className={`${isSidebarOpen ? "mx-[10px]" : "mx-0"}`}
            /> */}
            {bloomMenuItems.map((menuItem, index) => (
              <div key={index}>{renderMenuItem(menuItem)}</div>
            ))}
          </SidebarSection>
        </SidebarBody>
        <SidebarFooter>
          <SidebarSection>
            <SidebarItem onClick={onToggleSidebar}>
              <MenuIcon
                src={isSidebarOpen ? CloseLeftIcon : CloseRightIcon}
                opacity={0.6}
              />
              {isSidebarOpen && <SidebarLabel>Close</SidebarLabel>}
            </SidebarItem>
            <Dropdown>
              <DropdownButton>
                <span className="flex min-w-0 items-center gap-2">
                  <Avatar
                    src={currentUser.picture}
                    className="size-6"
                    square={false}
                    alt=""
                  />
                  {isSidebarOpen && (
                    <span className="min-w-0">
                      <span className="block truncate text-menu">
                        {currentUser.name}
                      </span>
                    </span>
                  )}
                </span>
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/my-profile">
                  <UserIcon className="!size-6" />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon className="!size-6" />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarSection>
        </SidebarFooter>
      </Sidebar>
    );
  };

  const renderSettingMenuItem = ({ label, slug }: IMenuItem) => {
    const isCurrent=pathname.startsWith(slug || '@@@');
    return (
      <SidebarItem
        current={isCurrent}
        href={slug}
        variant="secondary"
      >
        {isSidebarOpen && (
          <SidebarLabel className={`${isCurrent ? 'opacity-100' : 'opacity-60'}`}>{label}</SidebarLabel>
        )}
      </SidebarItem>
    )
  };

  const onGoHome = () => {
    navigate('/');
  };

  const getSettingsSidebar = () => {
    return (
      <Sidebar>
        <SidebarHeader>
          <div className="flex gap-2 items-center mb-[21px]">
            <Avatar src={LeftArrowIcon} square={true} outline={false} onClick={onGoHome} />
            <SidebarLabel className="text-title">Settings</SidebarLabel>
          </div>
        </SidebarHeader>
        <SidebarBody>
          <SidebarSection>
            <SidebarSectionLabel>ACCOUNT</SidebarSectionLabel>
            {renderSettingMenuItem({ label: 'Email & calendar', slug: '/settings/email-calendar' })}
          </SidebarSection>
          <SidebarSection>
            <SidebarSectionLabel>ORGANIZATION</SidebarSectionLabel>
            {renderSettingMenuItem({ label: 'Channels', slug: '/settings/channels' })}
            {renderSettingMenuItem({ label: 'Sales stages', slug: '/settings/sales-stages' })}
          </SidebarSection>
          <SidebarSection>
            <SidebarSectionLabel>KNOWLEDGE BASE</SidebarSectionLabel>
            {renderSettingMenuItem({ label: 'Branding', slug: '/settings/branding' })}
            {renderSettingMenuItem({ label: 'Tone of voice', slug: '/settings/tone-of-voice' })}
            {renderSettingMenuItem({ label: 'Products', slug: '/settings/products' })}
            {renderSettingMenuItem({ label: 'Personas', slug: '/settings/personas' })}
            {renderSettingMenuItem({ label: 'Use cases', slug: '/settings/use-cases' })}
            {/* {renderSettingMenuItem({ label: 'Proof', slug: '/settings/proof' })} */}
            {renderSettingMenuItem({ label: 'Competitors', slug: '/settings/competitors' })}
          </SidebarSection>
        </SidebarBody>
        {!pathname.startsWith('/settings') && (
        <SidebarFooter>
          <SidebarSection>
            <SidebarItem onClick={onToggleSidebar}>
              <MenuIcon
                src={isSidebarOpen ? CloseLeftIcon : CloseRightIcon}
                opacity={0.6}
              />
              {isSidebarOpen && <SidebarLabel>Close</SidebarLabel>}
            </SidebarItem>
            <Dropdown>
              <DropdownButton>
                <span className="flex min-w-0 items-center gap-2">
                  <Avatar
                    src={currentUser.picture}
                    className="size-6"
                    square={false}
                    alt=""
                  />
                  {isSidebarOpen && (
                    <span className="min-w-0">
                      <span className="block truncate text-menu">
                        {currentUser.name}
                      </span>
                    </span>
                  )}
                </span>
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/my-profile">
                  <UserIcon className="!size-6" />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon className="!size-6" />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarSection>
        </SidebarFooter>
        )}
      </Sidebar>
    );
  };

  return (
    <SidebarLayout
      isSidebarOpen={isSidebarOpen}
      navbar={
        <Navbar>
          <NavbarSpacer />
        </Navbar>
      }
      sidebar={pathname.startsWith('/setting') ? getSettingsSidebar() : getPrimarySidebar()}
    >
      {/* The page content */}
      <div className="flex flex-col h-full">
        {!shouldHideActionButton && (
          <div
          className="px-8 bg-white sticky top-0 z-50 border-b border-b-zinc-100 border-solid"
          id="sidebarHeader"
        >
          <div className="flex justify-between h-[64px] items-center">
            <div className="flex gap-6 items-center">
              <div className="flex gap-2 items-center">
                {pageIcon && (
                  <Avatar
                    square={false}
                    src={pageIcon}
                    outline={false}
                    className="size-8"
                  />
                )}
                <div className="text-title">
                  {pageTitle ?? businessData?.name}
                </div>
              </div>
              {tabs && !shouldHideTab && (
                <div
                  className="flex gap-5 text-sm leading-[17.5px] font-firstext items-center"
                  id="primaryTabs"
                >
                  {tabs.map((tab, index) => (
                    <a
                      key={tab.label + index}
                      href={getPageUrl(tab.slug)}
                      className={clsx(
                        isActiveTab(tab.slug!) ? "underline" : ""
                      )}
                    >
                      {tab.label}
                    </a>
                  ))}
                </div>
              )}
            </div>
            <div className="flex gap-2 items-center">
              {toolbar}
              {!shouldHideActionButton && (
                <CircularButton onClick={() => onDrawerOpen(true)}>
                  <EllipsisHorizontalIcon height={16} />
                </CircularButton>
              )}
            </div>
          </div>
          {tabs && !shouldHideTab && (
            <div
              className="border-t border-bloom-grey-line h-[48px] mb-1"
              id="secondaryTabs"
            >
              <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                {tabs.map((tab, index) => (
                  <a
                    key={tab.label + index}
                    href={getPageUrl(tab.slug)}
                    className={clsx(
                      isActiveTab(tab.slug!)
                        ? "border-black text-black"
                        : "border-transparent text-bloom-grey-menu",
                      "whitespace-nowrap border-t-2 py-[15px] text-menu"
                    )}
                  >
                    {tab.label}
                  </a>
                ))}
              </nav>
            </div>
          )}
          </div>
        )}
        <div className="flex-1 bg-white relative">{children}</div>
      </div>
      <MainDrawer isDrawerOpen={isDrawerOpen} setDrawerOpen={onDrawerOpen} />
      {sourceId && (
        <SourceDrawer
          isDrawerOpen={isSourceDrawerOpen}
          setDrawerOpen={onSourceDrawerOpen}
          sourceId={sourceId}
        />
      )}
      <NotificationDrawer
        isDrawerOpen={isInboxDrawerOpen}
        setDrawerOpen={onInboxDrawerOpen}
      />
      {/* {pathname !== "/dashboard" && (
        <AgentChatLayout>
          <div className="flex flex-col gap-4 items-center">
            <AgentInput size="lg" rounded />
          </div>
        </AgentChatLayout>
      )} */}
    </SidebarLayout>
  );
};

export default MainSidebar;
