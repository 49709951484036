import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { Textarea } from "@/components/cataylst/textarea";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { apiClient, localApi } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";
import { IFileUploadResult } from "@/types/file";
import { EllipsisIcon, FileImageIcon } from "@/components/custom/icons";
import { formatFileSize } from "@/components/custom/form/utils";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "@/components/cataylst/dropdown";
import Loading from "@/components/custom/loading";

interface IBranding {
  brandingId: string;
  brandName: string;
  missionStatement: string;
  vision: string;
  guidelinesDocumentPath: string;
  guidelinesDocumentSize: number;
  logoPath: string;
}

const emptyBrand: IBranding = {
  brandingId: "",
  brandName: "",
  missionStatement: "",
  vision: "",
  guidelinesDocumentPath: "",
  guidelinesDocumentSize: 0,
  logoPath: "",
};

export default function DemoBrandingPage() {
  const [brandDetails, setBrandDetails] = useState<IBranding>(emptyBrand);
  const [docFileUploadResult, setDocFileUploadResult] =
    useState<IFileUploadResult>();
  const [logoFileUploadResult, setLogoFileUploadResult] =
    useState<IFileUploadResult>();
  const [isLoading, setLoading] = useState(false);
  const [isInitialLoaded, setInitialLoaded] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState("Loading...");
  const docFileInputRef = useRef<HTMLInputElement>(null);
  const logoFileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setLoading(true);
    setLoadingTitle("Loading...");
    apiClient
      .get(`/agents/orgs/x-functions/knowledgebase-context/brandings`)
      .then((res) => {
        setInitialLoaded(true);
        if (res.data.items.length > 0) setBrandDetails(res.data.items[0]);
      })
      .catch((err) => {
        console.error("Error while fetching the branding: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onBrandingDetailsChange = (fieldName: string, value: any) => {
    const newBrandDetails = { ...brandDetails };
    newBrandDetails[fieldName] = value;
    setBrandDetails(newBrandDetails);
  };

  const onSaveKnowledgebase = async () => {
    try {
      setLoading(true);
      setLoadingTitle("Saving...");
      let newBrandDetails: IBranding = {
        ...brandDetails,
      };
      if (!newBrandDetails.brandingId) {
        const { data } = await apiClient.post(
          `/agents/orgs/x-functions/knowledgebase-context/brandings`,
          newBrandDetails
        );
        newBrandDetails.brandingId = data.brandingId;
        setBrandDetails({
          ...data,
        });
      } else
        await apiClient.put(
          `/agents/orgs/x-functions/knowledgebase-context/brandings/${newBrandDetails.brandingId}`,
          newBrandDetails
        );
      const { data: newKnowledgebaseFile } = await apiClient.post(
        `/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`,
        {
          fileName: `New-Branding-Knowledgebase-File-${newBrandDetails.brandingId}.txt`,
          contentType: "text",
          knowledgeBaseId: Environment.KNOWLEDGE_BASE_ID,
        }
      );
      const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
      await axios.put(filePresignedUrl, JSON.stringify(newBrandDetails), {
        headers: {
          "Content-Type": "text/plain",
        },
      });
    } catch (err) {
      console.error("Error while saving data: ", err);
    } finally {
      setLoading(false);
    }
  };

  const triggerDocFileInput = () => {
    if (docFileInputRef.current) docFileInputRef.current.click();
  };

  const triggerLogoFileInput = () => {
    if (logoFileInputRef.current) logoFileInputRef.current.click();
  };

  const onDocFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      setLoadingTitle("Uploading...");
      // Get the pre-signed URL
      const { data: fileUploadResult } = await apiClient.post(
        `/agents/orgs/x-functions/general/files/upload-url`,
        {
          fileName: file.name,
          category: "knowledgebase-branding-documents",
        }
      );

      setDocFileUploadResult(fileUploadResult);

      setBrandDetails({
        ...brandDetails,
        guidelinesDocumentPath: fileUploadResult.s3ObjectKey,
        guidelinesDocumentSize: file.size,
      });

      // Upload the file using the pre-signed URL
      await axios.put(fileUploadResult.uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onLogoFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      setLoadingTitle("Uploading...");
      // Get the pre-signed URL
      const { data: fileUploadResult } = await apiClient.post(
        `/agents/orgs/x-functions/general/files/upload-url`,
        {
          fileName: file.name,
          category: "knowledgebase-branding-logos",
        }
      );

      setLogoFileUploadResult(fileUploadResult);

      setBrandDetails({
        ...brandDetails,
        logoPath: fileUploadResult.s3ObjectKey,
      });

      // Upload the file using the pre-signed URL
      await axios.put(fileUploadResult.uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onViewDocFile = async () => {
    window.open(
      `${Environment.S3_STORAGE_URL}/${brandDetails.guidelinesDocumentPath}`,
      "_blank"
    );
  };

  const onViewLogoFile = async () => {
    window.open(
      `${Environment.S3_STORAGE_URL}/${brandDetails.logoPath}`,
      "_blank"
    );
  };

  const onDeleteDocFile = async () => {
    setDocFileUploadResult(undefined);
    setBrandDetails({
      ...brandDetails,
      guidelinesDocumentPath: "",
      guidelinesDocumentSize: 0,
    });
  };

  const onDeleteLogoFile = async () => {
    setLogoFileUploadResult(undefined);
    setBrandDetails({
      ...brandDetails,
      logoPath: "",
    });
  };

  return (
    <main>
      <MainSidebar
        pageTitle="Branding"
        toolbar={
          <Button color="dark" size="lg" onClick={onSaveKnowledgebase}>
            Save
          </Button>
        }
      >
        {isInitialLoaded && (
          <div className="text-cell px-8 py-5 max-w-[852px]">
            <div className="flex flex-col gap-2">
              <div className="font-medium">Brand name</div>
              <Textarea
                resizable={false}
                rows={1}
                withItems={null}
                type="text"
                value={brandDetails.brandName}
                onChange={(e) =>
                  onBrandingDetailsChange("brandName", e.target.value)
                }
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Brand mission statement</div>
              <Textarea
                resizable={false}
                withItems={null}
                type="text"
                value={brandDetails.missionStatement}
                onChange={(e) =>
                  onBrandingDetailsChange("missionStatement", e.target.value)
                }
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Brand vision</div>
              <Textarea
                resizable={false}
                withItems={null}
                type="text"
                value={brandDetails.vision}
                onChange={(e) =>
                  onBrandingDetailsChange("vision", e.target.value)
                }
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Brand logo</div>
              {brandDetails.logoPath && (
                <div className="w-full py-4 pl-4 pr-6 rounded-lg h-[64px] bg-[#F7F7F7] text-sm">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-3">
                      <img src={FileImageIcon} className="w-8 h-8" />
                      <div className="flex flex-col gap-0.5">
                        <div>{brandDetails.logoPath.split("/").pop()}</div>
                      </div>
                    </div>
                    <div>
                      <Dropdown>
                        <DropdownButton outline className="!border-none !p-0">
                          <img src={EllipsisIcon} className="w-4 h-4" />
                        </DropdownButton>
                        <DropdownMenu className="min-w-48">
                          <DropdownItem onClick={onViewLogoFile}>
                            View File
                          </DropdownItem>
                          <DropdownItem onClick={onDeleteLogoFile}>
                            Remove File
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="w-full border border-[#106EF6] border-dashed rounded-lg h-[64px] bg-[#F8FAFE] flex items-center justify-center text-sm cursor-pointer"
                onClick={triggerLogoFileInput}
              >
                Drag here to upload logo, or&nbsp;
                <span className="underline">browse files</span>
              </div>
              <input
                type="file"
                ref={logoFileInputRef}
                style={{ display: "none" }}
                onChange={onLogoFileUpload}
                accept=".png,.jpg,.bmp,.svg"
              />
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Brand guideline document</div>
              {brandDetails.guidelinesDocumentPath && (
                <div className="w-full py-4 pl-4 pr-6 rounded-lg h-[64px] bg-[#F7F7F7] text-sm">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-3">
                      <img src={FileImageIcon} className="w-8 h-8" />
                      <div className="flex flex-col gap-0.5">
                        <div>
                          {brandDetails.guidelinesDocumentPath.split("/").pop()}
                        </div>
                        <div className="opacity-60 text-xs">
                          DOC -{" "}
                          {formatFileSize(brandDetails.guidelinesDocumentSize)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <Dropdown>
                        <DropdownButton outline className="!border-none !p-0">
                          <img src={EllipsisIcon} className="w-4 h-4" />
                        </DropdownButton>
                        <DropdownMenu className="min-w-48">
                          <DropdownItem onClick={onViewDocFile}>
                            View File
                          </DropdownItem>
                          <DropdownItem onClick={onDeleteDocFile}>
                            Remove File
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="w-full border border-[#106EF6] border-dashed rounded-lg h-[64px] bg-[#F8FAFE] flex items-center justify-center text-sm cursor-pointer"
                onClick={triggerDocFileInput}
              >
                Drag here to upload document, or&nbsp;
                <span className="underline">browse files</span>
              </div>
              <input
                type="file"
                ref={docFileInputRef}
                style={{ display: "none" }}
                onChange={onDocFileUpload}
                accept=".pdf,.doc,.docx,.txt"
              />
            </div>
          </div>
        )}
        {isLoading && (
          <div className="w-full h-full absolute top-0 left-0 bg-[#AAAAAA66]">
            <div className="fixed top-[40vh] left-[55%]">
              <Loading title={loadingTitle} />
            </div>
          </div>
        )}
      </MainSidebar>
    </main>
  );
}
