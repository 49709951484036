import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { Textarea } from "@/components/cataylst/textarea";
import { MultiTextGroup } from "@/components/custom/multi-text-group";
import { useNavigate, useParams } from "react-router-dom";
import { ICompetitor } from "./DemoCompetitorsPage";
import { Input, InputGroup } from "@/components/cataylst/input";
import { MenuIcon } from "@/components/custom/menu-icon";
import { useEffect, useState } from "react";
import { apiClient } from "@/services/api";
import { Environment } from "@/utils/constants";
import axios from "axios";
import { DefaultCompetitorIcon } from "@/components/custom/icons";
import Loading from "@/components/custom/loading";

export const emptyCompetitor: ICompetitor = {
  competitorId: "",
  differentiation: [],
  icon: DefaultCompetitorIcon,
  name: "",
  valuePropositions: [],
  weaknesses: [],
  website: "",
};

export default function DemoCompetitorDetailpage() {
  const { competitorId } = useParams();
  const [competitorDetails, setCompetitorDetails] =
    useState<ICompetitor>(emptyCompetitor);
  const [isLoading, setLoading] = useState(false);
  const [isInitialLoaded, setInitialLoaded] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState("Loading...");
  const navigate = useNavigate();

  useEffect(() => {
    if (competitorId === "new") {
      setInitialLoaded(true);
      return;
    }
    setLoading(true);
    setLoadingTitle("Loading...");
    apiClient
      .get(
        `/agents/orgs/x-functions/knowledgebase-context/competitors/${competitorId}`
      )
      .then((res) => {
        setInitialLoaded(true);
        setCompetitorDetails({
          ...res.data,
          icon: DefaultCompetitorIcon,
        });
      })
      .catch((err) => {
        console.error("Error while fetching the competitor: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [competitorId]);

  const onCompetitorDetailsChange = (fieldName: string, value: any) => {
    const newCompetitorDetails = { ...competitorDetails };
    newCompetitorDetails[fieldName] = value;
    setCompetitorDetails(newCompetitorDetails);
  };

  const onSaveKnowledgebase = async () => {
    try {
      setLoading(true);
      setLoadingTitle("Saving...");
      let newCompetitorDetails: ICompetitor = {
        ...competitorDetails,
      };
      if (!competitorDetails.competitorId) {
        const { data } = await apiClient.post(
          `/agents/orgs/x-functions/knowledgebase-context/competitors`,
          newCompetitorDetails
        );
        newCompetitorDetails.competitorId = data.competitorId;
        setCompetitorDetails({
          ...data,
          icon: DefaultCompetitorIcon,
        });
      } else
        await apiClient.put(
          `/agents/orgs/x-functions/knowledgebase-context/competitors/${newCompetitorDetails.competitorId}`,
          newCompetitorDetails
        );
      const { data: newKnowledgebaseFile } = await apiClient.post(
        `/agents/orgs/x-functions/knowledgebases/${Environment.KNOWLEDGE_BASE_ID}/files/upload-url`,
        {
          fileName: `New-Competitor-Knowledgebase-File-${newCompetitorDetails.competitorId}.txt`,
          contentType: "text",
          knowledgeBaseId: Environment.KNOWLEDGE_BASE_ID,
        }
      );
      const filePresignedUrl = newKnowledgebaseFile.uploadUrl;
      await axios.put(filePresignedUrl, JSON.stringify(newCompetitorDetails), {
        headers: {
          "Content-Type": "text/plain",
        },
      });
      navigate(`/settings/competitors/${newCompetitorDetails.competitorId}`);
    } catch (err) {
      console.error("Error while saving data: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <MainSidebar
        pageTitle={`Competitors/${competitorDetails.name || "New"}`}
        // shouldHideActionButton
        toolbar={
          <Button color="dark" size="lg" onClick={onSaveKnowledgebase}>
            Save
          </Button>
        }
      >
        {isInitialLoaded && (
          <div className="text-cell px-8 py-5 max-w-[852px]">
            <div className="flex flex-col gap-2">
              <div className="font-medium">Competitor website</div>
              <InputGroup>
                <MenuIcon
                  src={DefaultCompetitorIcon}
                  data-slot="icon"
                  className="!size-4"
                />
                <Input
                  placeholder="Company website"
                  className="h-9 pl-9 pr-2 !rounded-lg"
                  value={`${competitorDetails?.website}`}
                  onChange={(e) =>
                    onCompetitorDetailsChange("website", e.target.value)
                  }
                />
              </InputGroup>
            </div>
            <div className="flex flex-col gap-2 mt-8">
              <div className="font-medium">Competitor name</div>
              <Textarea
                resizable={false}
                withItems={null}
                type="text"
                value={competitorDetails?.name}
                rows={1}
                onChange={(e) =>
                  onCompetitorDetailsChange("name", e.target.value)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add value prop"
                label={"Competitor value props"}
                defaultValues={competitorDetails?.valuePropositions}
                onChange={(texts) =>
                  onCompetitorDetailsChange("valuePropositions", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add weakness"
                label={"Competitor weaknesses"}
                defaultValues={competitorDetails?.weaknesses}
                onChange={(texts) =>
                  onCompetitorDetailsChange("weaknesses", texts)
                }
              />
            </div>
            <div className="mt-8">
              <MultiTextGroup
                buttonName="Add differentiator"
                label={"How Bloom differentiates"}
                defaultValues={competitorDetails?.differentiation}
                onChange={(texts) =>
                  onCompetitorDetailsChange("differentiation", texts)
                }
              />
            </div>
            <div className="mt-20"></div>
          </div>
        )}
        {isLoading && (
          <div className="w-full h-full absolute top-0 left-0 bg-[#AAAAAA66]">
            <div className="fixed top-[40vh] left-[55%]">
              <Loading title={loadingTitle} />
            </div>
          </div>
        )}
      </MainSidebar>
    </main>
  );
}
