import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { capitalCase } from 'change-case';
import { MessageArray } from '@/services/conversation';
import { AssistantMessage, Message, ToolCall } from '@/types/conversation';
import { Avatar } from '@/components/cataylst/avatar';
import { LogoIcon } from '@/components/custom/icons';

interface MessagesProps {
  messages: MessageArray;
  assistantName: string;
}

interface WithError {
  error?: {
    message: string;
  };
}

const Messages: React.FC<MessagesProps> = ({ messages, assistantName }) => {
  const showTools = (tool_calls: ToolCall[], receiving?: boolean) => {
    const getFunctionName = (name: string) => {
      if (name?.startsWith('searchKB')) return 'Checking Knowledgebase';
      if (name?.startsWith('kbInfo')) return 'Getting Knowledgebase Info';

      return capitalCase(name || 'Processing');
    };

    return (
      <div className='flex flex-wrap'>
        {tool_calls.map((tool) => (
          <div
            className='flex items-center p-2 py-1.5 rounded-sm bg-gray-300 text-sm'
            key={tool.id}
          >
            {receiving && !(tool as { completed?: boolean }).completed && (
              <>***</>
            )}
            {getFunctionName(tool.function?.name)}
          </div>
        ))}
      </div>
    );
  };

  const showMessageContent = (
    message: Message,
    receiving: boolean,
    sameSourceAsPrevious: boolean
  ) => (
    <>
      {!sameSourceAsPrevious && message.type === 'assistant' && (
        <div className='flex items-center'>
          <div className='flex gap-1 items-center font-bold'>
            <Avatar src={LogoIcon} square={true} outline={false} className='w-4 h-4' />
            {assistantName}
          </div>
        </div>
      )}
      <div style={{ marginLeft: 32 }}>
        {(message as AssistantMessage)?.tool_calls?.length
          ? showTools(
              (message as AssistantMessage).tool_calls as ToolCall[],
              receiving
            )
          : null}
        {(message as WithError).error && (
          <div className='text-red-500 mb-1 ml-2'>
            [Error]: {(message as WithError).error?.message}
          </div>
        )}
        <Markdown rehypePlugins={[rehypeRaw]}>
          {receiving
            ? `${message.content ?? ''}<span class="animate-pulse">⎮</span>`
            : message.content ?? ''}
        </Markdown>
      </div>
    </>
  );

  return (
    <div>
      {messages.map((message, i) => {
        const isUser = (type: string) =>
          type === 'user' ? 'user' : 'assistant';
        const receiving = (message as { receiving?: boolean }).receiving;
        const error = !!(message as WithError).error;
        const shouldShowTools =
          (message as AssistantMessage)?.tool_calls?.length ||
          receiving ||
          error;
        const sameSourceAsPrevious =
          i > 0 &&
          isUser(messages[i - 1].type || '') === isUser(message.type || '');

          switch (message.type) {
          case 'user':
          case 'assistant':
            if (!message.content && !shouldShowTools) return null;

            return (
              <div
                className='p-2 rounded-sm mb-1 mt-1 py-1'
                key={message.id}
              >
                {showMessageContent(
                  message as Message,
                  !!receiving,
                  sameSourceAsPrevious
                )}
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default Messages;