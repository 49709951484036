import MainSidebar from "@/components/custom/sidebar";
import { Button } from "@/components/custom/button";
import { ButtonPlusIcon, ChevronRightIcon, PlusIcon, SearchIcon } from "@/components/custom/icons";
import { Input, InputGroup } from "@/components/cataylst/input";
import { MenuIcon } from "@/components/custom/menu-icon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "@/services/api";
import { emptyUsecase } from "./DemoUseCaseDetailPage";
import Loading from "@/components/custom/loading";

export interface IUseCase {
  useCaseId: string;
  name: string;
  description: string;
  challenges: string[];
  desiredOutcomes: string[];
  relatedProof: string;
  icon: string;
}

interface IUseCaseItemProps {
  usecase: IUseCase;
  onClick?: Function;
  borderTop?: boolean;
}

function UseCaseItem({
  usecase: { name, description, icon },
  borderTop,
  onClick,
}: IUseCaseItemProps) {
  return (
    <div
      className={`flex items-center justify-between pr-2 py-4 font-firstext ${
        borderTop && "border-t border-solid border-t-gray-200"
      } border-b border-b-gray-200 cursor-pointer`}
      onClick={() => (onClick || (() => {}))()}
    >
      <div className="flex gap-3 items-center w-[90%]">
        <div className="flex-grow-[32px]">
          <img src={icon} className="w-8 h-8" />
        </div>
        <div className="flex flex-col gap-1 flex-1 max-w-full">
          <div className="font-semibold text-sm leading-[17.5px]">{name}</div>
          <div className="text-sm leading-[21px] opacity-60 pr-10 truncate">
            {description}
          </div>
        </div>
      </div>
      <div>
        <img src={ChevronRightIcon} className="w-4 h-4" />
      </div>
    </div>
  );
}

export default function DemoUseCasesPage() {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [usecases, setUsecases] = useState<IUseCase[]>([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
      setLoading(true);
      apiClient.get(`/agents/orgs/x-functions/knowledgebase-context/usecases`)
        .then(res => {
          if (res.data.items.length > 0) {
            setUsecases(res.data.items.map(item => ({
              ...item,
              icon: emptyUsecase.icon
            })));
          }
        })
        .catch((err) => {
          console.error("Error while fetching usecases: ", err);
        })
        .finally(() => {
          setLoading(false);
        })
    }, []);

  const onSearchUsecases = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const onAddUseCase = () => {
    navigate("/settings/use-cases/new");
  };
  const onEditUseCase = (usecase: IUseCase) => {
    navigate(`/settings/use-cases/${usecase.useCaseId}`);
  };

  const filterUsecases = () => {
    return usecases.filter(usecase => 
      usecase.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      usecase.description.toLowerCase().includes(searchKeyword.toLowerCase())
    );
  }

  return (
    <main>
      <MainSidebar
        pageTitle="Use cases"
        shouldHideActionButton
        toolbar={
          <Button color="dark" className="gap-2 flex items-center">
            <img src={PlusIcon} className="w-4 h-4" />
            Add use case
          </Button>
        }
      >
        <div className="text-cell px-8 py-5">
          <div className="flex flex-col gap-2 align-center">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon
                  src={SearchIcon}
                  data-slot="icon"
                  className="!size-4"
                />
                <Input
                  placeholder="Search usecases"
                  className="h-9 pl-9 pr-2 !rounded-full border-none !bg-[#F2F2F2]"
                  onChange={onSearchUsecases}
                  value={searchKeyword}
                  color=""
                />
              </InputGroup>
            </div>
          </div>
          {!isLoading && (
            <div className="mt-5">
              <div>
                {filterUsecases().map((usecase, index) => (
                  <UseCaseItem
                    usecase={usecase}
                    key={usecase.useCaseId}
                    borderTop={index === 0}
                    onClick={() => onEditUseCase(usecase)}
                  />
                ))}
              </div>
              <div
                className="w-full border mt-5 border-[#00000022] text-[#00000099] border-dashed rounded-lg px-4 h-[42px] flex gap-1.5 items-center text-sm cursor-pointer"
                onClick={onAddUseCase}
              >
                <img src={ButtonPlusIcon} className="w-4 h-4" />
                <span>Add usecase</span>
              </div>
            </div>
          )}
          {isLoading && (
            <div className="w-full h-full mt-4">
              <Loading />
            </div>
          )}
        </div>
      </MainSidebar>
    </main>
  );
}
